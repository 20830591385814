import JSEncrypt from "jsencrypt";
import { setToken, getToken } from '@/libs/util'
import { Message, Modal } from "view-ui-plus";
import store from '@/store'
import { AdminLogin } from "@/api/Login";
import { GetExamUser } from "@/api/Rbac";
import router from '@/router'
export default {
  state: {
    DepartmentName: "",
    merchantName: '',
    avatarImgPath: '',
    token: getToken(),
    seatNo: '',
    seatStatus: '',
    ShowHeaderBtn: '',
    StaffNo: ""
  },
  mutations: {
    setDepartmentName(state, name) {
      state.DepartmentName = name
    },
    setShowHeaderBtn(state, name) {
      state.ShowHeaderBtn = name
    },
    setMerchantName(state, name) {
      state.merchantName = name
    },
    setStaffNo(state, name) {
      state.StaffNo = name
    },
    setAvatar(state, avatarPath) {
      state.avatarImgPath = avatarPath
    },
    setToken(state, token) {
      state.token = token
      setToken(token)
    },
    setSeatNo(state, name) {
      state.seatNo = name
    },
    setSeatStatus(state, name) {
      state.seatStatus = name
    },

  },
  getters: {},
  actions: {
    // 登录
    handleLogin({ commit }, { UserName, Psw }) {
      return new Promise((resolve, reject) => {
        AdminLogin(
          UserName,
          Psw,
        ).then(data => {
          let menuData = data.Data.MenuTree
          // let menuData = []
          let userInfoData = {
            Avatar: '../assets/images/head.jpg',
            Access: []
          }
          userInfoData.merchantName = data.Data.UserInfo.TrueName;
          userInfoData.DepartmentName = data.Data.UserInfo.DepartmentName;
          userInfoData.ShowHeaderBtn = data.Data.UserInfo.ShowHeaderBtn;
          userInfoData.StaffNo = data.Data.UserInfo.StaffNo;
          userInfoData.Id = data.Data.UserInfo.Id;
          userInfoData.RoleNames = data.Data.UserInfo.RoleNames;
          // userInfoData.merchantName = "11";
          // userInfoData.DepartmentName = "11";
          // userInfoData.ShowHeaderBtn = "11";
          // userInfoData.StaffNo = "11";

          //调app.js  合并动态路由
          store.dispatch('setMenuData', menuData).then(res => { })
          //本地缓存用户信息
          sessionStorage.setItem("userInfo", JSON.stringify(userInfoData))
          store.dispatch('setUserInfo', userInfoData).then(res => { })
          //更新过期状态为登陆状态
          sessionStorage.setItem("loginState", 'login')
         
          //commit('setToken', data.Data.Token)
          // commit('setToken', "11")

          let encrypt = new JSEncrypt();
          // 设置公钥
          let publicKey =
            "MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHYRWdQfb0CbsTX2MTHPgKTDcswTYPmBfb6y08xcBwTTF36h9M0R2hflqU77pKe7CXU7svMAjMSuonb2JAjSl65PXQJDTCUI+YGhf++0BaFX+x92c1GwfdkBInlFmpBf+c8SBXUcqm0N6bEtK22ASGmvUlXyADVieIxLTyy4cVmPAgMBAAE=";
          encrypt.setPublicKey(publicKey);
          var userInfo = data.Data.UserInfo
          var loginToken = userInfo.Id+'|'+userInfo.StaffNo+'|'+userInfo.TrueName+'|'+userInfo.DepartmentId

           commit('setToken', encrypt.encrypt(loginToken))
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },
	handleExamLogin({ commit }, { TrueName, Mobile }) {
	  return new Promise((resolve, reject) => {
	    GetExamUser({
	      TrueName,
	      Mobile
	    }).then(data => {
			if(data.Data.length == 0){
				Message.warning("用户信息不存在，请确认是否输入正确");
				return
			}
	      let userInfoData = {
	        Avatar: '../assets/images/head.jpg',
	        Access: []
	      }
		  store.dispatch('setMenuData', []).then(res => { })
	      userInfoData.merchantName = data.Data[0].TrueName;
	      userInfoData.DepartmentName = data.Data[0].DepartmentName;
	      userInfoData.ShowHeaderBtn = data.Data[0].ShowHeaderBtn;
	      userInfoData.StaffNo = data.Data[0].StaffNo;
	      userInfoData.Id = data.Data[0].Id;
	      // userInfoData.merchantName = "11";
	      // userInfoData.DepartmentName = "11";
	      // userInfoData.ShowHeaderBtn = "11";
	      // userInfoData.StaffNo = "11";
	
	      //调app.js  合并动态路由
	      //本地缓存用户信息
	      sessionStorage.setItem("userInfo", JSON.stringify(userInfoData))
	      store.dispatch('setUserInfo', userInfoData).then(res => { })
	      //更新过期状态为登陆状态
	      sessionStorage.setItem("loginState", 'login')
	     
	      //commit('setToken', data.Data.Token)
	      // commit('setToken', "11")
	
	      let encrypt = new JSEncrypt();
	      // 设置公钥
	      let publicKey =
	        "MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHYRWdQfb0CbsTX2MTHPgKTDcswTYPmBfb6y08xcBwTTF36h9M0R2hflqU77pKe7CXU7svMAjMSuonb2JAjSl65PXQJDTCUI+YGhf++0BaFX+x92c1GwfdkBInlFmpBf+c8SBXUcqm0N6bEtK22ASGmvUlXyADVieIxLTyy4cVmPAgMBAAE=";
	      encrypt.setPublicKey(publicKey);
	      var userInfo = data.Data[0]
	      var loginToken = userInfo.Id+'|'+userInfo.StaffNo+'|'+userInfo.TrueName+'|'+userInfo.DepartmentId
	
	       commit('setToken', encrypt.encrypt(loginToken))
		  resolve()
	    }).catch(err => {
	      reject(err)
	    })
	  })
	},
    //本地缓存用户信息
    setUserInfo({ commit }, infoData) {
      commit('setMerchantName', infoData.merchantName)
      commit('setDepartmentName', infoData.DepartmentName)
      commit('setShowHeaderBtn', infoData.ShowHeaderBtn)
      commit('setStaffNo', infoData.StaffNo)
      commit('setAvatar', infoData.Avatar)
      commit('setSeatNo', infoData.seatNo)
      commit('setSeatStatus', infoData.seatStatus)
    },
    // 退出登录
    handleLogOut({ state, commit }) {
      return new Promise((resolve, reject) => {
        // 如果你的退出登录无需请求接口，则可以直接使用下面三行代码而无需使用logout调用接口
        commit('setToken', '')
        sessionStorage.clear();
        resolve()
      })
    },
    getEncrypt(data) {
      debugger
      let encrypt = new JSEncrypt();
      // 设置公钥
      let publicKey =
        "MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHYRWdQfb0CbsTX2MTHPgKTDcswTYPmBfb6y08xcBwTTF36h9M0R2hflqU77pKe7CXU7svMAjMSuonb2JAjSl65PXQJDTCUI+YGhf++0BaFX+x92c1GwfdkBInlFmpBf+c8SBXUcqm0N6bEtK22ASGmvUlXyADVieIxLTyy4cVmPAgMBAAE=";
      encrypt.setPublicKey(publicKey);

      console.log(encrypt.encrypt(data))

      return encrypt.encrypt(data);
    }
  }
}
