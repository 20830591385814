import { MyRequest } from '@/libs/api.request'

//用户列表
export const UserList = (Pars) => {
    const data = { Pars }
    return MyRequest({ url: `/api/Rbac/UserList`, method: 'post', data: data })
}

//保存用户
export const SaveUser = (params) => {
    const data = {
        Id: params.Id,
        UserNo: params.UserNo,
        TrueName: params.TrueName,
        DepartmentId: params.DepartmentId,
        IsEnable: params.isEnable,
        Mobile: params.Mobile,
        AreaName: params.AreaName,
        StationName: params.StationName,
        RoleId: params.RoleId,
    }
    return MyRequest({ url: `/api/Rbac/SaveUser`, method: 'post', data: data })
}

//删除用户
export const DelUser = (Pars) => {
    const data = { Pars }
    return MyRequest({ url: `/api/Rbac/DelUser`, method: 'post', data: data })
}

//重置密码
export const ResetPwd = (Pars) => {
    const data = { Pars }
    return MyRequest({ url: `/api/Rbac/ResetPwd`, method: 'post', data: data })
}

//部门列表
export const DeptList = () => {
    const data = {}
    return MyRequest({ url: `/api/Rbac/DeptList`, method: 'post', data: data })
}

//所在部门
export const OwnerDept = () => {
    const data = {}
    return MyRequest({ url: `/api/Rbac/OwnerDept`, method: 'post', data: data })
}

//部门树
export const DeptTree = () => {
    const data = {}
    return MyRequest({ url: `/api/Rbac/DeptTree`, method: 'post', data: data })
}

//保存部门
export const SaveDept = (params) => {
    const data = {
        DepartmentDesc: params.DepartmentDesc,
        DepartmentName: params.DepartmentName,
        Id: params.Id,
    }
    return MyRequest({ url: `/api/Rbac/SaveDept`, method: 'post', data: data })
}

//删除部门
export const DelDept = (Pars) => {
    const data = { Pars }
    return MyRequest({ url: `/api/Rbac/DelDept`, method: 'post', data: data })
}

//角色列表
export const RoleList = () => {
    const data = {}
    return MyRequest({ url: `/api/Rbac/RoleList`, method: 'post', data: data })
}

//保存角色
export const SaveRole = (params) => {
    const data = {
        RoleName: params.RoleName,
        RoleDesc: params.RoleDesc,
        Id: params.Id,
    }
    return MyRequest({ url: `/api/Rbac/SaveRole`, method: 'post', data: data })
}

//删除角色
export const DelRole = (Pars) => {
    const data = { Pars }
    return MyRequest({ url: `/api/Rbac/DelRole`, method: 'post', data: data })
}

//角色成员列表
export const RoleUsersList = (params) => {
    const data = {
        Pars: params.Pars,
        SearchVal: params.SearchVal
    }
    return MyRequest({ url: `/api/Rbac/RoleUsersList`, method: 'post', data: data })
}

//删除角色成员
export const DelRoleUsers = (Pars) => {
    const data = { Pars }
    return MyRequest({ url: `/api/Rbac/DelRoleUsers`, method: 'post', data: data })
}

//用户下的角色
export const UserRolesList = (Pars) => {
    const data = { Pars }
    return MyRequest({ url: `/api/Rbac/UserRolesList`, method: 'post', data: data })
}

//分配角色
export const UserBindRole = (params) => {
    return MyRequest({ url: `/api/Rbac/UserBindRole`, method: 'post', data: params })
}

//角色权限
export const RoleMenuTree = (Pars) => {
    const data = { Pars }
    return MyRequest({ url: `/api/Rbac/RoleMenuTree`, method: 'post', data: data })
}

//保存角色权限
export const SaveRoleMenu = (params) => {
    return MyRequest({ url: `/api/Rbac/SaveRoleMenu`, method: 'post', data: params })
}

//添加角色成员
export const RoleBindUsers = (params) => {
    return MyRequest({ url: `/api/Rbac/RoleBindUsers`, method: 'post', data: params })
}

export const AnswerUsers = () => {
    const data = {}
    return MyRequest({ url: `/api/Rbac/AnswerUsers`, method: 'post', data: data })
}

// 区列表
export const AreaList = () => {
    const data = {}
    return MyRequest({ url: `/api/BaseData/AreaList`, method: 'post', data: data })
}

// 站列表
export const StationList = (params) => {
    const data = { ...params }
    return MyRequest({ url: `/api/BaseData/StationList`, method: 'post', data: data })
}

// 添加、修改区（站）
export const SaveAreaStation = (params) => {
    const data = { ...params }
    return MyRequest({ url: `/api/BaseData/SaveAreaStation`, method: 'post', data: data })
}

// 删除区（站）
export const DelAreaStation = (params) => {
    const data = { ...params }
    return MyRequest({ url: `/api/BaseData/DelAreaStation`, method: 'post', data: data })
}


// 考生用户
export const GetExamUser = (params) => {
    const data = { ...params }
    return MyRequest({ url: `/api/Rbac/GetExamUser`, method: 'post', data: data })
}